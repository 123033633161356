@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@font-face {
    font-family: 'oe-icons';
    font-weight: normal;
    font-style: normal;
    src: url(/react/build/static/media/oe-icons.0ac15e75.ttf) format('truetype'),
         url(/react/build/static/media/oe-icons.5fdedae2.woff) format('woff');
  }
  
  [class^="oe-icon"], [class*="oe-icon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'oe-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 25px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  
  .oe-icon-rover:before {
  content: "\E900";
  }
  .oe-icon-question-line:before {
  content: "\E901";
  }
  .oe-icon-ban:before {
  content: "\E956";
  }
  .oe-icon-snowflake:before {
  content: "\E955";
  }
  .oe-icon-form-request-solid-circle:before {
  content: "\E936";
  }
  .oe-icon-form-request-solid:before {
  content: "\E954";
  }
  .oe-icon-contact-round-bottom:before {
  content: "\E902";
  }
  .oe-icon-rook:before {
  content: "\E964";
  }
  .oe-icon-eye:before {
  content: "\E963";
  }
  .oe-icon-eye-disable:before {
  content: "\E962";
  }
  .oe-icon-rook-circle:before {
  content: "\E961";
  }
  .oe-icon-new-circle:before {
  content: "\E960";
  }
  .oe-icon-crosshair:before {
  content: "\E951";
  }
  .oe-icon-video-circle:before {
  content: "\E952";
  }
  .oe-icon-search-circle:before {
  content: "\E950";
  }
  .oe-icon-chat-bubble-line-circle:before {
  content: "\E94F";
  }
  .oe-icon-new-subscriber-circle:before {
  content: "\E94E";
  }
  .oe-icon-consultation-circle:before {
  content: "\E94D";
  }
  .oe-icon-calculator-circle:before {
  content: "\E94C";
  }
  .oe-icon-review-cta-circle:before {
  content: "\E94B";
  }
  .oe-icon-apply-circle:before {
  content: "\E94A";
  }
  .oe-icon-estimate-circle:before {
  content: "\E949";
  }
  .oe-icon-social-share-circle:before {
  content: "\E948";
  }
  .oe-icon-chat-bubble-line:before {
  content: "\E946";
  }
  .oe-icon-box:before {
  content: "\E947";
  }
  .oe-icon-new-subscriber:before {
  content: "\E944";
  }
  .oe-icon-form-request:before {
  content: "\E945";
  }
  .oe-icon-page-circle:before {
  content: "\E943";
  }
  .oe-icon-home-circle:before {
  content: "\E93A";
  }
  .oe-icon-review-circle:before {
  content: "\E93B";
  }
  .oe-icon-newsletter-circle:before {
  content: "\E93C";
  }
  .oe-icon-home-report-circle:before {
  content: "\E93D";
  }
  .oe-icon-home-listing-circle:before {
  content: "\E93E";
  }
  .oe-icon-social-wall-circle:before {
  content: "\E93F";
  }
  .oe-icon-phone-conversation-circle:before {
  content: "\E940";
  }
  .oe-icon-message-circle:before {
  content: "\E941";
  }
  .oe-icon-pencil-circle:before {
  content: "\E942";
  }
  .oe-icon-box-circle:before {
  content: "\E937";
  }
  .oe-icon-snowflake-circle:before {
  content: "\E939";
  }
  .oe-icon-home:before, .oe-icon-cta-home:before {
  content: "\E938";
  }
  .oe-icon-linkedin-circle:before {
  content: "\E600";
  }
  .oe-icon-facebook-circle:before {
  content: "\E601";
  }
  .oe-icon-twitter-circle:before {
  content: "\E602";
  }
  .oe-icon-googleplus-circle:before {
  content: "\E603";
  }
  .oe-icon-instagram-circle:before {
  content: "\E604";
  }
  .oe-icon-foursquare-circle:before {
  content: "\E605";
  }
  .oe-icon-yelp-circle:before {
  content: "\E606";
  }
  .oe-icon-pinterest-circle:before {
  content: "\E607";
  }
  .oe-icon-linkedin-square:before {
  content: "\E608";
  }
  .oe-icon-facebook-square:before {
  content: "\E609";
  }
  .oe-icon-twitter-square:before {
  content: "\E60A";
  }
  .oe-icon-googleplus-square:before {
  content: "\E60B";
  }
  .oe-icon-instagram-square:before {
  content: "\E60C";
  }
  .oe-icon-foursquare-square:before {
  content: "\E60D";
  }
  .oe-icon-yelp-square:before {
  content: "\E60E";
  }
  .oe-icon-pinterest-square:before {
  content: "\E60F";
  }
  .oe-icon-typewriter-line:before {
  content: "\E610";
  }
  .oe-icon-calendar:before, .oe-icon-cta-calendar:before {
  content: "\E611";
  }
  .oe-icon-filter-line:before {
  content: "\E612";
  }
  .oe-icon-key-circle:before {
  content: "\E613";
  }
  .oe-icon-target:before {
  content: "\E614";
  }
  .oe-icon-thumbsup-circle:before {
  content: "\E615";
  }
  .oe-icon-palette:before {
  content: "\E616";
  }
  .oe-icon-drop:before {
  content: "\E617";
  }
  .oe-icon-estimate:before, .oe-icon-cta-estimate:before  {
  content: "\E618";
  }
  .oe-icon-logo:before {
  content: "\E619";
  }
  .oe-icon-logo-circle:before {
  content: "\E61A";
  }
  .oe-icon-contacts-circle:before {
  content: "\E61B";
  }
  .oe-icon-speech-bubble-circle:before {
  content: "\E61C";
  }
  .oe-icon-star-circle:before {
  content: "\E61D";
  }
  .oe-icon-swatch:before {
  content: "\E61E";
  }
  .oe-icon-calendar-blank:before {
  content: "\E61F";
  }
  .oe-icon-speech-bubble:before, .oe-icon-cta-speech-bubble:before {
  content: "\E620";
  }
  .oe-icon-star:before, .oe-icon-cta-star:before  {
  content: "\E621";
  }
  .oe-icon-apply:before, .oe-icon-cta-apply:before  {
  content: "\E622";
  }
  .oe-icon-home-line:before, .oe-icon-cta-home-line:before {
  content: "\E623";
  }
  .oe-icon-consultation:before, .oe-icon-cta-consultation:before {
  content: "\E624";
  }
  .oe-icon-about:before, .oe-icon-cta-about:before {
  content: "\E625";
  }
  .oe-icon-home-report:before, .oe-icon-cta-home-report:before {
  content: "\E626";
  }
  .oe-icon-social:before, .oe-icon-cta-social:before {
  content: "\E627";
  }
  .oe-icon-newsletter:before, .oe-icon-cta-newsletter:before {
  content: "\E628";
  }
  .oe-icon-envelope:before, .oe-icon-cta-envelope:before {
  content: "\E629";
  }
  .oe-icon-calendar-alt:before, .oe-icon-cta-calendar-alt:before {
  content: "\E62A";
  }
  .oe-icon-review:before, .oe-icon-cta-review:before {
  content: "\E62B";
  }
  .oe-icon-calculator:before, .oe-icon-cta-calculator:before {
  content: "\E62C";
  }
  .oe-icon-digital-0:before {
  content: "\E62D";
  }
  .oe-icon-digital-2:before {
  content: "\E62E";
  }
  .oe-icon-digital-3:before {
  content: "\E62F";
  }
  .oe-icon-digital-4:before {
  content: "\E630";
  }
  .oe-icon-digital-5:before {
  content: "\E631";
  }
  .oe-icon-digital-6:before {
  content: "\E632";
  }
  .oe-icon-digital-7:before {
  content: "\E633";
  }
  .oe-icon-digital-8:before {
  content: "\E634";
  }
  .oe-icon-digital-9:before {
  content: "\E635";
  }
  .oe-icon-digital-1:before {
  content: "\E636";
  }
  .oe-icon-img:before {
  content: "\E637";
  }
  .oe-icon-img-frame:before {
  content: "\E638";
  }
  .oe-icon-text:before {
  content: "\E639";
  }
  .oe-icon-circle-check:before {
  content: "\E63A";
  }
  .oe-icon-circle:before {
  content: "\E63B";
  }
  .oe-icon-key:before {
  content: "\E800";
  }
  .oe-icon-user-circle:before {
  content: "\E801";
  }
  .oe-icon-add-dashed-circle:before {
  content: "\E802";
  }
  .oe-icon-globe:before {
  content: "\E803";
  }
  .oe-icon-globe-circle:before {
  content: "\E804";
  }
  .oe-icon-zillow-square:before {
  content: "\E805";
  }
  .oe-icon-yelp:before {
  content: "\E806";
  }
  .oe-icon-dashed-circle:before {
  content: "\E807";
  }
  .oe-icon-plus:before {
  content: "\E808";
  }
  .oe-icon-avvo:before {
  content: "\E809";
  }
  .oe-icon-zillow-circle:before {
  content: "\E903";
  }
  .oe-icon-bullhorn:before {
  content: "\E904";
  }
  .oe-icon-envelope-circle:before {
  content: "\E905";
  }
  .oe-icon-flame:before {
  content: "\E906";
  }
  .oe-icon-contact:before {
  content: "\E907";
  }
  .oe-icon-googleplus:before {
  content: "\E908";
  }
  .oe-icon-linkedin:before {
  content: "\E909";
  }
  .oe-icon-facebook:before {
  content: "\E90A";
  }
  .oe-icon-twitter:before {
  content: "\E90B";
  }
  .oe-icon-trophy:before {
  content: "\E90C";
  }
  .oe-icon-angle-left:before {
  content: "\E90D";
  }
  .oe-icon-angle-right:before {
  content: "\E90E";
  }
  .oe-icon-flame-circle:before {
  content: "\E90F";
  }
  .oe-icon-bullhorn-circle:before {
  content: "\E910";
  }
  .oe-icon-contact-circle:before {
  content: "\E911";
  }
  .oe-icon-trophy-circle:before {
  content: "\E912";
  }
  .oe-icon-ban-circle:before {
  content: "\E913";
  }
  .oe-icon-contacts:before {
  content: "\E914";
  }
  .oe-icon-zillow:before {
  content: "\E915";
  }
  .oe-icon-lawyers-circle:before {
  content: "\E916";
  }
  .oe-icon-flame-square:before {
  content: "\E917";
  }
  .oe-icon-tag-circle:before {
  content: "\E918";
  }
  .oe-icon-angieslist-circle:before {
  content: "\E919";
  }
  .oe-icon-trulia-circle:before {
  content: "\E91A";
  }
  .oe-icon-youtube-circle:before {
  content: "\E91B";
  }
  .oe-icon-youtube:before {
  content: "\E91C";
  }
  .oe-icon-phone-circle:before {
  content: "\E91D";
  }
  .oe-icon-calendar-circle:before {
  content: "\E91E";
  }
  .oe-icon-info-circle:before {
  content: "\E91F";
  }
  .oe-icon-avvo-circle:before {
  content: "\E920";
  }
  .oe-icon-frankenstein:before {
  content: "\E921";
  }
  .oe-icon-question-circle:before {
  content: "\E922";
  }
  .oe-icon-caret-down:before {
  content: "\E923";
  }
  .oe-icon-caret-up:before {
  content: "\E924";
  }
  .oe-icon-gear:before {
  content: "\E925";
  }
  .oe-icon-filter:before {
  content: "\E926";
  }
  .oe-icon-sort-list:before {
  content: "\E927";
  }
  .oe-icon-sort-grid:before {
  content: "\E928";
  }
  .oe-icon-sort-data:before {
  content: "\E929";
  }
  .oe-icon-search:before {
  content: "\E92A";
  }
  .oe-icon-readychat:before {
  content: "\E92B";
  }
  .oe-icon-chat-bubble:before {
  content: "\E92C";
  }
  .oe-icon-flag-circle:before {
  content: "\E92D";
  }
  .oe-icon-transcript-line:before {
  content: "\E92E";
  }
  .oe-icon-transcript:before {
  content: "\E92F";
  }
  .oe-icon-ellipsis-circle:before {
  content: "\E930";
  }
  .oe-icon-times-circle:before {
  content: "\E931";
  }
  .oe-icon-check-circle-line:before {
  content: "\E932";
  }
  .oe-icon-ellipsis:before {
  content: "\E933";
  }
  .oe-icon-yeild:before {
  content: "\E934";
  }
  .oe-icon-circle-line:before {
  content: "\E935";
  }
  .oe-icon-check:before {
  content: "\E953";
  }
  .oe-icon-realtor-circle:before {
  content: "\E957";
  }
  .oe-icon-realtor:before {
  content: "\E958";
  }
  .oe-icon-google-my-business:before {
  content: "\E959";
  }
  .oe-icon-google-my-business-circle:before {
  content: "\E95A";
  }
  .oe-icon-google-my-business-square:before {
  content: "\E95B";
  }
/* eslint-disable-next-line no-use-before-define */
/* eslint-disable-next-line no-use-before-define */
:root {
  --footer-bg: #28414F;
  --link-blue: #3FA9F5;
  --soft-black: #333;
  --btn-green: #61D69C;
  --social-wall-header-gray: #8b99a7;
  --twitter: #00BFFF;
  --linkedin: #1E90FF;
  --google-instagram: #FF4500;
  --facebook: #0000CD; }

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

body {
  padding-top: 2.9rem;
  /* Remove if .fixed-top is removed from nav element */
  font-display: swap;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: #333;
  color: var(--soft-black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.grecaptcha-badge {
  visibility: hidden; }

button {
  border-radius: 2px; }

/* Social Icons */
.company-globe-blog {
  color: darkblue; }

/* Override Bootstrap Classes Start */
.bg-dark {
  background-color: #333 !important;
  background-color: var(--soft-black) !important; }

/* Override Bootstrap Classes End */
/********************************
** FOOTER OE-ICON-OVERRIDES
*********************************/
.address-item {
  font-weight: 400; }

.address-item.phone a,
.address-item.email a {
  color: white;
  text-decoration: none; }

.address-item.phone a:hover,
.address-item.email a:hover {
  text-decoration: underline; }

.address-item.icons span a {
  text-decoration: none; }

.success-link.phone a {
  color: black;
  text-decoration: none; }

.oe-icon-globe,
.oe-icon-pencil-circle,
.oe-icon-linkedin-square,
.oe-icon-linkedin-circle,
.oe-icon-facebook-square,
.oe-icon-facebook-circle,
.oe-icon-googleplus-square,
.oe-icon-googleplus-circle,
.oe-icon-twitter-square,
.oe-icon-twitter-circle,
.oe-icon-pinterest-square,
.oe-icon-pinterest-circle,
.oe-icon-yelp-square,
.oe-icon-yelp-circle,
.oe-icon-foursquare-square,
.oe-icon-foursquare-circle,
.oe-icon-instagram-square,
.oe-icon-instagram-circle,
.oe-icon-youtube,
.oe-icon-zillow-square,
.oe-icon-zillow-circle,
.oe-icon-avvo,
.oe-icon-lawyers-circle,
.oe-icon-realtor-circle,
.oe-icon-google-my-business,
.oe-icon-google-my-business-circle,
.oe-icon-google-my-business-square {
  font-size: 1.35em; }

/********************************
** GENERAL STYLES
*********************************/
.app {
  overflow: hidden; }

.navbar {
  padding: .22rem 1rem;
  -webkit-font-smoothing: auto; }

ul.navbar-nav li {
  margin: 0 1.5em;
  font-size: 1.15em; }

.dropdown-menu {
  border: none; }

.dropdown-menu :hover {
  background-color: transparent !important; }

.dropdown-menu a {
  color: #8b99a7;
  color: var(--social-wall-header-gray);
  text-decoration: none; }

.dropdown-menu p :hover {
  color: #CACACA; }

.spinner {
  height: 1000px; }

img.logo {
  max-width: 25%; }

/* Header Styles Start */
.header-overlay {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6)));
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  background-size: cover; }

.hero-large {
  color: white;
  background-color: #28414F;
  background-color: var(--footer-bg);
  background-size: cover;
  min-height: 460px;
  background-position: 50% 37%;
  background-repeat: no-repeat; }

.hero-large-overlay {
  min-height: 460px; }

.hero-small {
  padding-top: 15px;
  color: white;
  background-color: #28414F;
  background-color: var(--footer-bg);
  background-size: cover;
  min-height: 380px;
  background-position: 50% 37%;
  background-repeat: no-repeat;
  text-transform: capitalize; }

.hero-small-overlay {
  min-height: 380px; }

.greeting {
  margin-top: 49px;
  padding: 0 10%; }
  .greeting.no-headshot {
    margin-top: 90px; }
  .greeting h1 {
    margin: 80px 0 0 0; }
  .greeting p {
    padding-top: 15px;
    font-size: 1.6rem;
    margin-bottom: 120px; }

.header-small {
  margin-bottom: 33px; }

.header-small-greeting {
  margin-top: 50px;
  text-shadow: 1px 1px 5px #000000; }
  .header-small-greeting p {
    font-size: 1.6rem;
    margin-bottom: 150px; }

.hero-large h1 {
  font-size: 5em;
  font-weight: 300; }

.header-info {
  font-size: 1.6em;
  font-weight: 300;
  line-height: 1.5;
  padding: 38px 0 0; }

.header-info p {
  margin: 10px auto 30px auto; }

.header-info p.client-company {
  margin-bottom: 10px; }

.hero-small h1 {
  font-size: 5em;
  font-weight: 300; }

.header-large .headshot-image img {
  height: 205px;
  width: 205px;
  border-radius: 50%;
  border: 3px solid #FFF;
  position: relative;
  margin-top: -100px; }

.headshot-image img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  border: 3px solid #FFF;
  position: relative;
  margin-top: -50px;
  left: 7px; }

/* Header Styles End */
/* Footer Styles Start */
.footer {
  background-color: #28414F;
  background-color: var(--footer-bg);
  color: white;
  padding: 20px;
  font-size: .95em; }

.footer .col-sm-4 {
  display: flex; }

.footer .vcard {
  margin: 0 auto;
  max-width: 100%; }

.footer .footer-nav-links,
.footer .footer-subscribe {
  margin: 0 auto; }

.footer .address {
  line-height: 1.7; }

.footer .icons a {
  color: white;
  margin-right: 8px; }

.footer .icons a:hover {
  color: grey; }

.footer-nav-links li {
  list-style-type: none;
  line-height: 1.8; }

.footer-nav-links li a {
  font-weight: 400;
  text-decoration: none;
  color: white; }

.footer-nav-links li a:hover {
  color: grey; }

.footer label {
  font-size: 12px; }

.footer-subscribe {
  width: 90%; }

.footer .subscribe-btn {
  border-radius: 2px;
  background-color: #5E9DC2;
  color: white;
  text-transform: uppercase; }

.footer input::-webkit-input-placeholder {
  font-size: 12px;
  color: gray;
  opacity: 0.5;
  /* Firefox */ }

.footer input::-moz-placeholder {
  font-size: 12px;
  color: gray;
  opacity: 0.5;
  /* Firefox */ }

.footer input::-ms-input-placeholder {
  font-size: 12px;
  color: gray;
  opacity: 0.5;
  /* Firefox */ }

.footer input::placeholder {
  font-size: 12px;
  color: gray;
  opacity: 0.5;
  /* Firefox */ }

.footer input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: gray; }

.footer input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: gray; }

.footer .disclaimer-cert-container {
  font-size: 1.2em; }

.footer-sub input {
  min-width: 100%;
  max-width: 100%;
  height: 2.7em; }

.footer-sub input::-webkit-input-placeholder {
  font-size: 14px;
  color: black;
  padding-left: 5px; }

.footer-sub input::-moz-placeholder {
  font-size: 14px;
  color: black;
  padding-left: 5px; }

.footer-sub input::-ms-input-placeholder {
  font-size: 14px;
  color: black;
  padding-left: 5px; }

.footer-sub input::placeholder {
  font-size: 14px;
  color: black;
  padding-left: 5px; }

.footer-sub label {
  font-weight: 400;
  font-size: 1em;
  display: block; }

.footer-sub-btn button {
  padding: 7px 20px;
  float: right; }

.footer-sub-error {
  max-width: 100%;
  padding-left: 5px;
  font-weight: normal;
  line-height: 2em; }

.footer-sub-form {
  max-width: 100%; }

/* Footer Styles End */
/********************************
** HOME PAGE STYLES
*********************************/
.subhead {
  margin-bottom: 40px; }

.subhead h2 {
  font-size: 2em;
  font-weight: 300;
  color: #333;
  color: var(--soft-black);
  margin-bottom: 40px; }

.subhead button,
.subhead .contact-me-btn {
  display: block;
  font-size: 1.4em;
  font-weight: 300;
  height: 55px;
  width: 340px;
  color: #fff;
  border-radius: 0;
  border: none;
  background-color: #F6754E;
  margin-bottom: 40px;
  box-shadow: 0px 0px 4px gray;
  cursor: pointer; }

.subhead .contact-me-btn {
  font-weight: 400;
  text-decoration: none;
  padding: 10px;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }

.subhead .contact-me-btn:hover,
.subhead .contact-me-btn:focus {
  background-color: rgba(246, 117, 78, 0.9);
  color: #3b3b3b; }

.subhead .about-img {
  width: 70%;
  padding: 84px 0 45px; }

.subhead .about-img img {
  max-width: 100%;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content; }

.subhead .logo-img img {
  width: 100%; }

.subhead .arrow {
  height: 23px;
  width: 23px;
  border-right: 3px solid #e6e6e6;
  border-bottom: 3px solid #e6e6e6;
  margin: 0 auto 60px;
  -webkit-transform: rotate(35deg) skew(-20deg);
          transform: rotate(35deg) skew(-20deg); }

.subhead .bio div {
  font-size: 1.3em;
  width: 70%; }

.subscribe-subhead {
  margin-bottom: 50px; }

.subscribe-subhead h2 {
  font-size: 2em;
  font-weight: 300;
  color: #333;
  color: var(--soft-black);
  margin-bottom: 10px; }

.header-links-component p.pagelink,
.contact-cta p.pagelink {
  display: inline-block;
  padding: 0 15px;
  font-size: 1.1em;
  line-height: 2.5; }

.header-links-component div.pagelink:nth-child(odd),
.header-links-component p.pagelink:nth-child(odd) {
  padding: 0; }

.header-links-component div.pagelink,
.header-links-component p.pagelink a {
  font-weight: 400;
  color: #3FA9F5;
  color: var(--link-blue);
  cursor: pointer; }

.header-links-component div.pagelink {
  display: inline-block;
  padding: 0 15px;
  font-size: 1.1em; }

.header-links-component div.pagelink:hover {
  text-decoration: underline; }

.header-links-component span.divider {
  height: 100%;
  border: 1px solid #3FA9F5;
  border: 1px solid var(--link-blue);
  padding: 0; }

.card-body img.card-logo {
  margin: -30px 10px 0 0;
  width: 80px; }

.star-rating {
  display: inline-block;
  margin-bottom: 25px; }

.star-rating {
  margin-left: 10px; }

.star-rating i.fas {
  color: skyblue;
  border: lightgray; }

.star-rating i.far {
  color: lightgray; }

.review-card {
  margin-bottom: 30px; }

.review-card p.review-text {
  min-height: 100px; }

.review-card p.reviewer-info {
  margin-right: 35px; }

.review-card p,
.modal .card-body p {
  /* display: inline-block; */
  font-size: 0.85em; }

.review-card p.reviewer-info,
.review-card .review-date,
.modal .card-body p.reviewer-info,
.modal .card-body .review-date {
  font-size: 0.85em;
  font-weight: 400;
  margin-bottom: 0; }

.newsletters .home-page {
  margin-bottom: 165px; }

.newsletters .card {
  padding: 10px; }

.newsletters .card-body {
  padding: 20px 0; }

.newsletters .card-img-top {
  margin-bottom: 0; }

.newsletters .card-body h3 {
  font-size: 1em; }

.newsletters .card-body .card-text-more {
  margin-bottom: 0; }

.newsletters .card-body p.card-text-main {
  min-height: 80px; }

.newsletters .card .card-footer {
  border: 1px solid rgba(0, 0, 0, 0.125); }

.newsletters .card-footer small,
.newsletters .card-footer span {
  display: inline-block; }

span.read-more {
  font-size: .85em;
  font-weight: 400; }

.google-map {
  color: white;
  background-image: url(/react/build/static/media/google-map.20be2801.png);
  background-color: #28414F;
  background-color: var(--footer-bg);
  min-height: 480px;
  background-position: 50% 37%;
  background-repeat: no-repeat; }

#map {
  height: 30vh;
  width: 100vw; }

/* Optional: Makes the sample page fill the window. */
html, body {
  height: 100%;
  margin: 0;
  padding: 0; }

/********************************
** NEWSLETTERS STYLES
*********************************/
.newsletters .news-page {
  margin-bottom: 40px; }

.contact-cta .cta-contact-link,
.contact-cta a.cta-contact-link {
  display: inline-block;
  margin: 5px 0;
  padding: 0 20px;
  font-weight: 400;
  font-size: 1.3em;
  line-height: 2.5;
  border: none;
  color: #3FA9F5;
  color: var(--link-blue);
  cursor: pointer; }

.contact-cta a.cta-contact-link:hover {
  text-decoration: none; }

button.cta-contact-link {
  background-color: white; }

.contact-cta span.divider {
  height: 100%;
  border: 1px solid #3FA9F5;
  border: 1px solid var(--link-blue);
  padding: 0; }

.contact-icon {
  padding-right: 7px; }

.newsletter-social-row {
  font-size: 2.6em; }

.pinterest-icon {
  color: #ba0918; }

.youtube-icon {
  color: #ff0000; }

.yelp-icon {
  color: #ad0707; }

.foursquare-icon {
  color: #fc5677; }

.zillow-icon {
  color: #0075e3; }

.avvo-icon {
  color: #00457a; }

.lawyerscircle-icon {
  color: #8fc7f2; }

.realtorscircle-icon {
  color: #f21d32; }

.twitter-icon {
  color: #00BFFF;
  color: var(--twitter); }

.linkedin-icon {
  color: #1E90FF;
  color: var(--linkedin); }

.googleplus-icon {
  color: #FF4500;
  color: var(--google-instagram); }

.facebook-icon {
  color: #0000CD;
  color: var(--facebook); }

.instagram-icon {
  color: #FF4500;
  color: var(--google-instagram); }

.single-newsletter-image {
  width: 100%; }

.campaign-content table {
  margin: 0 auto; }

/********************************
** NEWSFEED STYLES (Social Wall)
*********************************/
.social-wall-header {
  margin-left: 200px;
  color: #8b99a7;
  color: var(--social-wall-header-gray); }

/********************************
** MODAL STYLES
*********************************/
.modal.show .modal-dialog {
  margin-top: 50px; }

.modal-body .headshot-image img {
  height: 85px;
  width: 85px; }

.modal #materialize .submit-btn-wrapper {
  margin-bottom: 0; }

/* Modal Component */
.modal-overlay-div {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.65); }

.modal-dialog-div {
  position: relative;
  outline: 0;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  max-width: 520px;
  cursor: default;
  border-radius: 4px; }

@-webkit-keyframes modal {
  0% {
    top: -150px;
    opacity: 0; }
  100% {
    top: 0;
    opacity: 1; } }

@keyframes modal {
  0% {
    top: -150px;
    opacity: 0; }
  100% {
    top: 0;
    opacity: 1; } }

.modal-global .modal-body {
  padding-bottom: 0; }

.modal-global .card-body {
  text-align: left; }

.modal-global .review-card {
  padding: 0; }

.modal-global .header-info p.client-name {
  font-size: 1.2em;
  line-height: 1.2; }

.modal-global .headshot-image img {
  margin-top: -60px;
  left: 22px; }

.modal-global p.review-text {
  min-height: 100px; }

/********************************
** REVIEWS STYLES
*********************************/
.reviews .review-card .card {
  cursor: pointer; }

.reviews .review-card .card:hover {
  box-shadow: 0 0 3px #9e9e9e; }

.leave-review-button {
  margin: 0 auto; }

/* Modal Styles Start */
.modal p.reviewer-info,
.modal .review-date {
  font-size: 0.85em;
  font-weight: 400; }

/* Modal Styles End */
/********************************
** VIDEOS STYLES
*********************************/
.subhead button.videos-contact-btn {
  background-color: #61D69C; }

.video-iframe iframe {
  margin-bottom: 55px; }

/********************************
** CONTACT STYLES
*********************************/
.required-fields {
  margin: 5px 0 8px 220px; }

.md-form .react-datepicker-wrapper {
  width: 100%; }
  .md-form .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%; }
    .md-form .react-datepicker-wrapper .react-datepicker__input-container input {
      width: 100%; }

.md-form .react-datepicker-wrapper {
  border-bottom: 1.1px solid #999; }

.md-form .modal-form .form-group {
  margin: 10px 0; }

.md-form .modal-form .header-info p.client-name {
  margin-bottom: 10px; }

.md-form .modal-form .modal-section-message {
  margin-bottom: 0; }

.md-form .modal-form .general-inquiry {
  margin-top: 20px; }

.md-form .modal-star-rating {
  position: relative;
  left: 50%;
  margin-left: -97px; }

.md-form .form-group.modal-email {
  display: block;
  width: 100%; }

.md-form .button-container {
  text-align: center; }

.md-form .chat-form .form-group {
  margin: 50px 0; }

.md-form .contact-form-container {
  width: 60%; }

.md-form .form-group.chat-text-field,
.md-form .form-group.chat-phone-field,
.md-form .form-group.chat-message-field {
  margin: 6px 0;
  display: block;
  width: 100%; }
  .md-form .form-group.chat-text-field p.danger,
  .md-form .form-group.chat-phone-field p.danger,
  .md-form .form-group.chat-message-field p.danger {
    margin-bottom: 0; }

.md-form .recaptcha-container {
  position: relative;
  left: 50%;
  margin-left: -160px; }

.md-form .recaptcha-chat-container {
  position: relative;
  left: 50%;
  margin-left: -152px; }

.md-form .hidden {
  display: none; }

.md-form p.error.danger {
  color: red; }

.md-form fieldset {
  margin: 0 0 3rem;
  padding: 0;
  border: none; }

.md-form .form-subhead {
  font-size: 1.6em;
  font-weight: 300;
  margin-bottom: 1.5em; }

.md-form .form-group.feedback,
.md-form .form-group.general-inquiry,
.md-form .form-group.review-form-modal,
.md-form .form-group.google-address,
.md-form .form-group.referrer-message {
  display: block;
  width: 100%;
  border: 1px solid #999;
  border-radius: .5em; }

.md-form .form-group.referrer-email {
  display: block; }

.md-form .form-radio,
.md-form .form-group {
  position: relative;
  display: inline-block;
  width: 50%;
  padding: 0 15px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: left; }

.md-form .form-inline > .form-group,
.md-form .form-inline > .btn {
  display: inline-block;
  margin-bottom: 0; }

.md-form .form-help {
  margin-top: 0.125rem;
  margin-left: 0.125rem;
  color: #b3b3b3;
  font-size: 0.8rem; }

.md-form .checkbox .form-help, .md-form .form-radio .form-help, .md-form .form-group .form-help {
  position: absolute;
  width: 100%; }

.md-form .checkbox .form-help {
  position: relative;
  margin-bottom: 1rem; }

.md-form .form-radio .form-help {
  padding-top: 0.25rem;
  margin-top: -1rem; }

.md-form .form-group input {
  height: 1.9rem; }

.md-form .form-group textarea {
  resize: none; }

.md-form .form-group select {
  width: 100%;
  font-size: 1rem;
  height: 1.9rem;
  padding: 0.125rem 0.125rem 0.0625rem;
  background: none;
  border: none;
  line-height: 1.6;
  box-shadow: none; }

.md-form .form-group .control-label {
  position: absolute;
  top: 0.25rem;
  pointer-events: none;
  padding-left: 0.125rem;
  color: #b3b3b3;
  font-size: 1rem;
  font-weight: normal;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease; }

.md-form .form-group.chat-message-field .control-label {
  padding-top: 8px; }

.md-form .form-group .bar {
  position: relative;
  border-bottom: 1.1px solid #999;
  display: block; }

.md-form .form-group .bar::before {
  content: '';
  height: 0.125rem;
  width: 0;
  left: 50%;
  bottom: -0.0625rem;
  position: absolute;
  background: #337ab7;
  -webkit-transition: left 0.28s ease, width 0.28s ease;
  transition: left 0.28s ease, width 0.28s ease;
  z-index: 2; }

.md-form .form-group input,
.md-form .form-group textarea {
  display: block;
  background: none;
  padding: 0.125rem 0.125rem 0.0625rem;
  font-size: 1rem;
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: 100%;
  color: transparent;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none; }

.md-form .form-group input[type="file"] {
  line-height: 1; }

.md-form .form-group input[type="file"] ~ .bar {
  display: none; }

.md-form .form-group select,
.md-form .form-group input:focus,
.md-form .form-group input:valid,
.md-form .form-group input.form-file,
.md-form .form-group input.has-value,
.md-form .form-group textarea:focus,
.md-form .form-group textarea:valid,
.md-form .form-group textarea.form-file,
.md-form .form-group textarea.has-value {
  color: #333; }

.md-form .form-group select ~ .control-label,
.md-form .form-group input:focus ~ .control-label,
.md-form .form-group input:valid ~ .control-label,
.md-form .form-group input.form-file ~ .control-label,
.md-form .form-group input.has-value ~ .control-label,
.md-form .form-group textarea:focus ~ .control-label,
.md-form .form-group textarea:valid ~ .control-label,
.md-form .form-group textarea.form-file ~ .control-label,
.md-form .form-group textarea.has-value ~ .control-label {
  font-size: 0.8rem;
  color: gray;
  top: -1rem;
  left: 0; }

.md-form .form-group.general-inquiry select ~ .control-label,
.md-form .form-group.general-inquiry input:focus ~ .control-label,
.md-form .form-group.general-inquiry input:valid ~ .control-label,
.md-form .form-group.general-inquiry input.form-file ~ .control-label,
.md-form .form-group.general-inquiry input.has-value ~ .control-label,
.md-form .form-group.general-inquiry textarea:focus ~ .control-label,
.md-form .form-group.general-inquiry textarea:valid ~ .control-label,
.md-form .form-group.general-inquiry textarea.form-file ~ .control-label,
.md-form .form-group.general-inquiry textarea.has-value ~ .control-label,
.md-form .form-group.referrer-message select ~ .control-label,
.md-form .form-group.referrer-message input:focus ~ .control-label,
.md-form .form-group.referrer-message input:valid ~ .control-label,
.md-form .form-group.referrer-message input.form-file ~ .control-label,
.md-form .form-group.referrer-message input.has-value ~ .control-label,
.md-form .form-group.referrer-message textarea:focus ~ .control-label,
.md-form .form-group.referrer-message textarea:valid ~ .control-label,
.md-form .form-group.referrer-message textarea.form-file ~ .control-label,
.md-form .form-group.referrer-message textarea.has-value ~ .control-label {
  font-size: 0.8rem;
  color: gray;
  top: -1.5rem;
  left: 0; }

.md-form .form-group select:focus,
.md-form .form-group input:focus,
.md-form .form-group textarea:focus {
  outline: none; }

.md-form .form-group select:focus ~ .control-label,
.md-form .form-group input:focus ~ .control-label,
.md-form .form-group textarea:focus ~ .control-label,
.md-form .form-group.general-inquiry select:focus ~ .control-label,
.md-form .form-group.general-inquiry input:focus ~ .control-label,
.md-form .form-group.general-inquiry textarea:focus ~ .control-label,
.md-form .form-group.referrer-message select:focus ~ .control-label,
.md-form .form-group.referrer-message input:focus ~ .control-label,
.md-form .form-group.referrer-message textarea:focus ~ .control-label {
  color: #337ab7; }

.md-form .form-group select:focus ~ .bar::before,
.md-form .form-group input:focus ~ .bar::before,
.md-form .form-group textarea:focus ~ .bar::before {
  width: 100%;
  left: 0; }

.md-form .checkbox label,
.md-form .form-radio label {
  position: relative;
  cursor: pointer;
  padding-left: 2rem;
  text-align: left;
  color: #333;
  display: block; }

.md-form .checkbox input,
.md-form .form-radio input {
  width: auto;
  opacity: 0;
  position: absolute;
  left: 0; }

.md-form p.recruiting-date-time-control-label {
  font-weight: 400;
  min-height: 25px;
  font-size: 0.8rem;
  color: gray;
  top: -1rem;
  left: 0;
  margin-bottom: 0;
  margin-top: -26px; }

.md-form p.date-time-control-label {
  font-weight: 400;
  min-height: 25px;
  font-size: 0.8rem;
  color: gray;
  top: -1rem;
  left: 0;
  margin-bottom: 0; }

.md-form .radio {
  display: inline-block;
  margin-bottom: 1rem; }

.md-form .form-radio label {
  display: inline-block;
  margin: 0 5px; }

.md-form .radio .helper {
  position: absolute;
  left: -0.25rem;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #999; }

.md-form .radio .helper::before, .md-form .radio .helper::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 0.25rem;
  width: 1rem;
  height: 1rem;
  -webkit-transition: -webkit-transform 0.28s ease;
  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
  border-radius: 50%;
  border: 0.125rem solid currentColor; }

.md-form .radio .helper::after {
  -webkit-transform: scale(0);
  transform: scale(0);
  background-color: #337ab7;
  border-color: #337ab7; }

.md-form .radio label:hover .helper {
  color: #337ab7; }

.md-form .radio input:checked ~ .helper::after {
  -webkit-transform: scale(0.5);
  transform: scale(0.5); }

.md-form .radio input:checked ~ .helper::before {
  color: #337ab7; }

.md-form .checkbox {
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 40%; }

.md-form .checkbox .helper {
  color: #999;
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  z-index: 0;
  margin-top: 4px;
  border: 0.125rem solid currentColor;
  border-radius: 0.0625rem;
  -webkit-transition: border-color 0.28s ease;
  transition: border-color 0.28s ease; }

.md-form .checkbox .helper::before, .md-form .checkbox .helper::after {
  position: absolute;
  height: 0;
  width: 0.2rem;
  background-color: #337ab7;
  display: block;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-radius: 0.25rem;
  content: '';
  -webkit-transition: opacity 0.28s ease, height 0s linear 0.28s;
  transition: opacity 0.28s ease, height 0s linear 0.28s;
  opacity: 0; }

.md-form .checkbox .helper::before {
  top: 0.65rem;
  left: 0.38rem;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  box-shadow: 0 0 0 0.0625rem #fff; }

.md-form .checkbox .helper::after {
  top: 0.3rem;
  left: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.md-form .checkbox label:hover .helper {
  color: #337ab7; }

.md-form .checkbox input:checked ~ .helper {
  color: #337ab7; }

.md-form .checkbox input:checked ~ .helper::after, .md-form .checkbox input:checked ~ .helper::before {
  opacity: 1;
  -webkit-transition: height 0.28s ease;
  transition: height 0.28s ease; }

.md-form .checkbox input:checked ~ .helper::after {
  height: 0.5rem; }

.md-form .checkbox input:checked ~ .helper::before {
  height: 1.2rem;
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s; }

.md-form .checkbox + .checkbox {
  margin-top: 1rem; }

.md-form .has-error .legend.legend, .md-form .has-error.form-group .control-label.control-label {
  color: #d9534f; }

.md-form .has-error.form-group .form-help,
.md-form .has-error.form-group .helper, .md-form .has-error.checkbox .form-help,
.md-form .has-error.checkbox .helper, .md-form .has-error.radio .form-help,
.md-form .has-error.radio .helper, .md-form .has-error.form-radio .form-help,
.md-form .has-error.form-radio .helper {
  color: #d9534f; }

.md-form .has-error .bar::before {
  background: #d9534f;
  left: 0;
  width: 100%; }

.md-form .button {
  position: relative;
  background: currentColor;
  border: 1px solid currentColor;
  font-size: 1.1rem;
  color: #4f93ce;
  margin: 3rem 0;
  padding: 0.75rem 3rem;
  cursor: pointer;
  -webkit-transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
  transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
  overflow: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.md-form .button span {
  color: #fff;
  position: relative;
  z-index: 1; }

.md-form .button::before {
  content: '';
  position: absolute;
  background: #071017;
  border: 50vh solid #1d4567;
  width: 30vh;
  height: 30vh;
  border-radius: 50%;
  display: block;
  top: 50%;
  left: 50%;
  z-index: 0;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0); }

.md-form .button:hover {
  color: #337ab7;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2); }

.md-form .button:active::before, .md-form .button:focus::before {
  -webkit-transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
  transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
  transition: transform 1.12s ease, opacity 0.28s ease 0.364s;
  transition: transform 1.12s ease, opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 0; }

.md-form .button:focus {
  outline: none; }

@media screen and (max-width: 576px) {
  .md-form .form-radio,
  .md-form .form-group {
    position: relative;
    display: block;
    width: 100%; }
  .md-form .form-group,
  .md-form .form-radio {
    margin: 10px 0; }
  .md-form .form-group.field-type-select {
    margin-top: 20px; }
  .md-form .submit-btn-wrapper p {
    font-size: .85em; }
  .md-form p.recruiting-date-time-control-label {
    margin-top: 0; } }

/********************************
** MODAL FORM CONTACT STYLES
*********************************/
.contact-btn {
  border-radius: 2px;
  color: #fff;
  background-color: #61D69C;
  box-shadow: 0px 0px 4px gray; }

.form-context-msg {
  font-size: 1.2em;
  font-weight: 300;
  margin-bottom: 1.5em; }

/********************************
** SUBMIT SUCCESS ANIMATION STYLES **
*********************************/
.check_mark {
  width: 80px;
  height: 130px;
  margin: 0 auto; }

button {
  cursor: pointer;
  margin-left: 15px; }

.hide {
  display: none; }

.sa-icon {
  width: 80px;
  height: 80px;
  border: 4px solid gray;
  border-radius: 40px;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box; }

.sa-icon.sa-success {
  border-color: #4CAF50; }

.sa-icon.sa-success::before, .sa-icon.sa-success::after {
  content: '';
  border-radius: 40px;
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background: white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.sa-icon.sa-success::before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px; }

.sa-icon.sa-success::after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0px 60px;
  transform-origin: 0px 60px; }

.sa-icon.sa-success .sa-placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(76, 175, 80, 0.5);
  border-radius: 40px;
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2; }

.sa-icon.sa-success .sa-fix {
  width: 5px;
  height: 90px;
  background-color: white;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.sa-icon.sa-success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in; }

.sa-icon.sa-success {
  border-color: transparent\9; }

.sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg) \9; }

.sa-icon.sa-success .sa-line.sa-long {
  -ms-transform: rotate(-45deg) \9; }

.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s; }

.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s; }

@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0px;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0px;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

.sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #4CAF50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2; }

.sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  right: 8px;
  top: 39px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.submitSuccessMessage {
  word-break: break-all; }

@-webkit-keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); } }

@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); } }

/********************************
** CHAT-WINDOW STYLES **
*********************************/
.chat-window {
  border: none;
  width: 20rem;
  height: 29.7rem;
  position: fixed;
  box-shadow: 0px 0px 20px gray;
  bottom: -490px;
  right: 10px;
  z-index: 9998;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-name: chat-popup;
          animation-name: chat-popup;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.slide-in-form {
  border: none;
  width: 20rem;
  height: 25.7rem;
  position: fixed;
  bottom: 5px;
  right: -340px;
  z-index: 9999;
  -webkit-animation-name: slide-in;
          animation-name: slide-in;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.slide-in-form .card-body {
  padding: 0 10px 10px 10px; }

@-webkit-keyframes chat-popup {
  0% {
    bottom: -478px; }
  100% {
    bottom: 5px; } }

@keyframes chat-popup {
  0% {
    bottom: -478px; }
  100% {
    bottom: 5px; } }

@-webkit-keyframes slide-in {
  0% {
    right: -340px; }
  100% {
    right: 10px; } }

@keyframes slide-in {
  0% {
    right: -340px; }
  100% {
    right: 10px; } }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.chat-window .card-body {
  padding: 0; }

p.chat-disclaimer {
  font-size: 10px; }

.chat-card .card-header {
  background-color: #3FA9F5;
  background-color: var(--link-blue);
  color: white; }

.chat-card .card-header button {
  color: white; }

.chat-card .card-body .chat-image img {
  height: 65px;
  width: 65px;
  border-radius: 50%; }

.chat-card .card-body .chat-header {
  font-size: .85rem;
  font-weight: 500;
  margin: 16px;
  padding: 0; }

.chat-card .card-body .chat-form-header {
  font-size: .85rem;
  font-weight: 500;
  padding: 0; }

.chat-card .card-body .chat-header-no-image {
  font-size: .85rem;
  font-weight: 500;
  margin: 25px 18px;
  padding: 0; }

.chat-card .card-body .chat-image-heading {
  margin: 0;
  height: 130px; }

.chat-card .card-body .chat-textarea {
  margin-top: 10px;
  line-height: 1.5; }

.chat-card .card-body .chat {
  height: 245px;
  font-weight: 500;
  overflow: auto; }

.chat-card .card-body .chat-input button {
  background-color: #3FA9F5;
  background-color: var(--link-blue);
  color: white;
  margin: 0; }

.chat-success {
  line-height: 1.5;
  opacity: 0;
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.chat-close-btn {
  border-radius: 2px;
  color: #fff;
  background-color: #3FA9F5;
  background-color: var(--link-blue);
  box-shadow: 0px 0px 4px gray; }

/********************************
** UNSUBSCRIBE STYLES
*********************************/
.unsubscribe-form {
  width: 350px;
  font-size: 1.1em; }

.unsubscribe-form label {
  padding-left: 10px; }

.unsubscribe-btn {
  background-color: #61D69C;
  border: none; }

.form-group.unsubscribe-hidden {
  display: block;
  width: 42%; }

.form-group.unsubscribe-hidden input {
  width: 100%;
  height: 28px;
  padding-top: 0; }

.form-group.unsubscribe-hidden textarea {
  width: 100%;
  height: 28px;
  padding-top: 0; }

.form-group.resub {
  display: inline-block;
  width: 42%; }

.resub-btn {
  display: inline-block; }

.resub-btn button {
  margin-bottom: 5px; }

/********************************
** CUSTOM PAGE STYLES
*********************************/
.custom-container {
  width: 65%;
  margin: 0 auto; }

.custom-layout {
  margin: 55px 0 50px 0; }

.text-layout {
  margin: 35px 0 70px 0; }

.custom-layout img {
  max-width: 100%;
  height: auto; }

.text-only {
  width: 97%; }

.custom-right {
  padding-left: 18px; }

/********************************
** FEEDBACK SUCCESS PAGE STYLES
********************************/
.feedback-success-img {
  margin: 35px 0 30px 0; }

.feedback-success-img .feedback-headshot-image img {
  height: 205px;
  width: 205px;
  border-radius: 50%;
  border: 3px solid #FFF; }

.feedback-headshot-image img {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  border: 3px solid #FFF; }

.success {
  margin-top: 110px; }

.success-links-component span.divider {
  height: 60%;
  border: 1px solid #9eabb8;
  padding-top: 5px;
  display: inline-block; }

.success-links-component p.pagelink {
  display: inline-block;
  padding: 0 4px;
  margin-bottom: 0; }

.social-link a {
  text-decoration: none;
  font-size: 1.5rem; }

/***********************************
** KELLER WILLIAMS RECRUITING STYLES
************************************/
.kwr-content {
  width: 73%;
  font-size: 1.3rem; }

.kwr-heading {
  margin: 50px 0; }

.kwr-heading h1 {
  font-size: 2.1rem; }

.kwr-button {
  display: block;
  font-size: 1.4em;
  height: 50px;
  width: 180px;
  color: #fff;
  border-radius: .25rem;
  border: none;
  background-color: #F6754E;
  margin-bottom: 40px;
  font-weight: 400;
  padding: 8px;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }

.kwr-button-container a:hover {
  background-color: rgba(246, 117, 78, 0.9);
  color: #3b3b3b;
  text-decoration: none; }

/***********************************
** BROWSER NOT SUPPORTED STYLES
************************************/
.not-supported-heading h1 {
  font-size: 4.9rem;
  margin: 110px 0 15px 0; }

.not-supported-heading h6 {
  font-size: 1.5rem;
  margin-bottom: 70px; }

.not-supported-heading p {
  font-size: 1.25rem;
  font-weight: 500; }

.not-supported-image {
  margin-bottom: 87px; }
  .not-supported-image img {
    max-width: 60%; }

/********************************
** MEDIA QUERY STYLES
*********************************/
@media screen and (max-width: 768px) {
  .subhead .about-img {
    width: 100%;
    padding: 42px 0 25px; }
  .success-links-component p.pagelink:nth-child(even) {
    display: none; }
  .success-links-component p.pagelink {
    display: block;
    margin-bottom: 4px; }
  .md-form .contact-form-container {
    width: 100%; }
  .required-fields {
    margin: 6px 0 16px 5px; } }

@media screen and (max-width: 576px) {
  /* General Styles Start */
  .review-card p.review-text {
    min-height: 120px; }
  img.logo {
    max-width: 65%; }
  /* General Styles End */
  /* Header Styles Start */
  .hero-large {
    min-height: 250px; }
  .hero-small {
    min-height: 190px; }
    .hero-small h1 {
      padding-top: 0; }
  .hero-large h1,
  .hero-small h1 {
    font-size: 2.4rem; }
  .header-large .headshot-image img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 3px solid #FFF;
    position: relative;
    margin-top: -60px; }
  .header-info {
    font-size: 1.4rem;
    line-height: 1.4; }
    .header-info p {
      margin-bottom: 1rem; }
    .header-info p.client-company {
      margin-bottom: 1rem; }
  .header-info p.client-name {
    line-height: 1.2; }
  .greeting h1 {
    margin: 30px 20px 10px; }
  .greeting p {
    padding-top: 0;
    font-size: 1.2rem;
    margin-bottom: 80px; }
  .header-small-greeting h1 {
    margin: 12px 20px 10px; }
  .header-small-greeting p {
    font-size: 1.2rem;
    margin-bottom: 80px; }
  .home-contact-btn {
    margin-top: 1rem !important; }
  .subhead .about-img {
    width: 100%;
    padding: 42px 0 25px; }
  .subhead {
    margin-bottom: 25px; }
    .subhead img {
      margin-bottom: 0; }
    .subhead .bio div {
      font-size: 1.1rem;
      width: 88%; }
  .subhead .contact-me-btn {
    width: 250px; }
  .modal .header-info {
    line-height: 1.5; }
  .form-context-msg {
    font-size: 1em; }
  /* Header Styles End */
  /* Homepage Styles Start */
  .reviews p.review-item:nth-child(odd),
  .newsletters p.newsletter-item:nth-child(odd) {
    padding: 0 3px; }
  .reviews p.review-item,
  .reviews .review-item.modal-link,
  .newsletters p.newsletter-item {
    padding: 0 3px; }
  /* Homepage Styles End */
  /* Reviews Styles Start */
  .review-card p.reviewer-info {
    margin-right: 80px; }
  /* Reviews Styles End */
  /* Newsletter Styles Start */
  .contact-cta {
    font-size: 0.85em; }
  .newsletters .home-page {
    margin-bottom: 30px; }
  .newsletters .card-body p.card-text-main {
    min-height: 20px; }
  .newsletters .card-footer span {
    margin-left: 100px; }
  .contact-cta .cta-contact-link,
  .contact-cta a.cta-contact-link,
  .contact-cta p.pagelink {
    padding: 0 6px; }
  /* Newsletter Styles End */
  /* Modal Styles Start */
  .modal .card-body p.reviewer-info,
  .modal .card-body .review-date {
    font-size: 0.75em; }
  .modal-form .header-info p.client-name {
    margin-bottom: 10px; }
  /* Modal Styles End */
  /* Footer Styles Start */
  .footer .col-sm-4 {
    padding: 20px 0;
    text-align: center; }
  .footer .subscribe-btn.float-right {
    float: none !important; }
  /* Footer Styles End */
  .header-links-component div.pagelink,
  .header-links-component p.pagelink {
    padding: 0 6px;
    font-size: .95em; }
  /* Form styles */
  form.md-form {
    margin-top: 8px; }
    form.md-form .checkbox {
      width: 80%; }
  .form-group.unsubscribe-hidden {
    width: 80%; }
  .form-group.unsubscribe-hidden input {
    width: 100%; }
  .form-group.resub {
    display: block;
    width: 80%; }
  .resub-btn {
    display: block; }
  .footer-sub-form {
    margin: 0 auto; }
  .footer-sub-btn button {
    float: none;
    margin: 0 auto; }
  .greeting.no-headshot {
    margin-top: 60px; }
  .header-small {
    margin-top: 17px; }
  .custom-layout {
    margin: 20px 0 30px 0; }
  .custom-right {
    margin-top: 17px;
    padding: 0; }
  .custom-container {
    width: 90%;
    margin: 0 auto; }
  .header-info {
    padding: 20px 0 0; }
  .kwr-heading {
    margin: 28px 0; }
  .kwr-heading h1 {
    font-size: 1.9rem; }
  .kwr-content {
    width: 82%; }
  .not-supported-heading h1 {
    font-size: 2.5rem;
    margin: 55px 0 20px 0; }
  .not-supported-heading h6 {
    font-size: 1.1rem;
    margin-bottom: 45px; }
  .not-supported-heading p {
    font-size: 1.15rem; }
  .not-supported-image {
    margin-bottom: 53px; }
    .not-supported-image img {
      max-width: 95%; } }

.unsubscribedEmail {
  display: block;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #333333; }

.honey {
  display: none;
  visibility: hidden; }

