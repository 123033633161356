/* eslint-disable-next-line no-use-before-define */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400');
/* eslint-disable-next-line no-use-before-define */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

:root {
    --footer-bg: #28414F;
    --link-blue: #3FA9F5;
    --soft-black: #333;
    --btn-green: #61D69C;
    --social-wall-header-gray: #8b99a7;
    --twitter: #00BFFF;
    --linkedin: #1E90FF;
    --google-instagram: #FF4500;
    --facebook: #0000CD;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  padding-top: 2.9rem; /* Remove if .fixed-top is removed from nav element */
  font-display: swap;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: var(--soft-black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grecaptcha-badge {
    visibility: hidden;
}

button {
    border-radius: 2px;
}

/* Social Icons */
.company-globe-blog {
    color: darkblue;
}

/* Override Bootstrap Classes Start */
.bg-dark {
    background-color: var(--soft-black) !important;
}
/* Override Bootstrap Classes End */

/********************************
** FOOTER OE-ICON-OVERRIDES
*********************************/
.address-item {
  font-weight: 400;
}
.address-item.phone a,
.address-item.email a {
    color: white;
    text-decoration: none;
}
.address-item.phone a:hover,
.address-item.email a:hover {
    text-decoration: underline;

}
.address-item.icons span a {
    text-decoration: none;
}

.success-link.phone a {
    color: black;
    text-decoration: none;
}

.oe-icon-globe,
.oe-icon-pencil-circle,
.oe-icon-linkedin-square,
.oe-icon-linkedin-circle,
.oe-icon-facebook-square,
.oe-icon-facebook-circle,
.oe-icon-googleplus-square,
.oe-icon-googleplus-circle,
.oe-icon-twitter-square,
.oe-icon-twitter-circle,
.oe-icon-pinterest-square,
.oe-icon-pinterest-circle,
.oe-icon-yelp-square,
.oe-icon-yelp-circle,
.oe-icon-foursquare-square,
.oe-icon-foursquare-circle,
.oe-icon-instagram-square,
.oe-icon-instagram-circle,
.oe-icon-youtube,
.oe-icon-zillow-square,
.oe-icon-zillow-circle,
.oe-icon-avvo,
.oe-icon-lawyers-circle,
.oe-icon-realtor-circle,
.oe-icon-google-my-business,
.oe-icon-google-my-business-circle,
.oe-icon-google-my-business-square
{
    font-size: 1.35em;
}

/********************************
** GENERAL STYLES
*********************************/
.app {
  overflow: hidden;
}
.navbar {
    padding: .22rem 1rem;
    -webkit-font-smoothing: auto;
}
ul.navbar-nav li {
  margin: 0 1.5em;
  font-size: 1.15em;
}

.dropdown-menu {
    border: none;
}

.dropdown-menu :hover {
    background-color: transparent !important;
}

.dropdown-menu a {
    color: var(--social-wall-header-gray);
    text-decoration: none;
}

.dropdown-menu p :hover {
    color: #CACACA;
}

.spinner {
    height: 1000px;
}

img.logo {
    max-width: 25%;
}

/* Header Styles Start */
.header-overlay {
    background: linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6));
    background-size: cover;
}

.hero-large {
    color: white;
    background-color: var(--footer-bg);
    background-size: cover;
    min-height: 460px;
    background-position: 50% 37%;
    background-repeat: no-repeat;
}

.hero-large-overlay {
    min-height: 460px;
}

.hero-small {
    padding-top: 15px;
    color: white;
    background-color: var(--footer-bg);
    background-size: cover;
    min-height: 380px;
    background-position: 50% 37%;
    background-repeat: no-repeat;
    text-transform: capitalize;
}

.hero-small-overlay {
    min-height: 380px;
}

.greeting {
    margin-top: 49px;
    padding: 0 10%;

    &.no-headshot {
        margin-top: 90px;
    }

    h1{
      margin: 80px 0 0 0;
    }

    p {
      padding-top: 15px;
      font-size: 1.6rem;
      margin-bottom: 120px;
    }
}

.header-small {
    margin-bottom: 33px;
}

.header-small-greeting {
    margin-top: 50px;
    text-shadow: 1px 1px 5px #000000;

    p {
      font-size: 1.6rem;
      margin-bottom: 150px;
    }
}

.hero-large h1 {
    font-size: 5em;
    font-weight: 300;
}

.header-info {
    font-size: 1.6em;
    font-weight: 300;
    line-height: 1.5;
    padding: 38px 0 0;
}

.header-info p {
    margin: 10px auto 30px auto;
}

.header-info p.client-company {
    margin-bottom: 10px;
}

.hero-small h1 {
    // padding-top: 80px;
    font-size: 5em;
    font-weight: 300;
}

.header-large .headshot-image img {
    height: 205px;
    width: 205px;
    border-radius: 50%;
    border: 3px solid #FFF;
    position: relative;
    margin-top: -100px;
}

.headshot-image img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 3px solid #FFF;
    position: relative;
    margin-top: -50px;
    left: 7px;
}
/* Header Styles End */

/* Footer Styles Start */
.footer {
    background-color: var(--footer-bg);
    color: white;
    padding: 20px;
    font-size: .95em;
}

.footer .col-sm-4 {
    display: flex;
}

.footer .vcard {
    margin: 0 auto;
    max-width: 100%;
}

.footer .footer-nav-links,
.footer .footer-subscribe {
    margin: 0 auto;
}

.footer .address {
    line-height: 1.7;
}

.footer .icons a {
    color: white;
    margin-right: 8px;
}
.footer .icons a:hover {
    color: grey;
}

.footer-nav-links li {
    list-style-type: none;
    line-height: 1.8;
}

.footer-nav-links li a {
    font-weight: 400;
    text-decoration: none;
    color: white;
}

.footer-nav-links li a:hover {
    color: grey;
}

.footer label {
    font-size: 12px;
}

.footer-subscribe {
    width: 90%;
}

.footer .subscribe-btn {
    border-radius: 2px;
    background-color: #5E9DC2;
    color: white;
    text-transform: uppercase;
}

.footer input::placeholder {
    font-size: 12px;
    color: gray;
    opacity: 0.5; /* Firefox */
}

.footer input::-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: gray;
}

.footer input::-ms-input-placeholder { /* Microsoft Edge */
   color: gray;
}

.footer .disclaimer-cert-container {
    font-size: 1.2em;
}

.footer-sub input {
    min-width: 100%;
    max-width: 100%;
    height: 2.7em;
}

.footer-sub input::placeholder {
    font-size: 14px;
    color: black;
    padding-left: 5px;
}

.footer-sub label {
    font-weight: 400;
    font-size: 1em;
    display: block;
}

.footer-sub-btn button {
    padding: 7px 20px;
    float: right;
}

.footer-sub-error {
    max-width: 100%;
    padding-left: 5px;
    font-weight: normal;
    line-height: 2em;
}

.footer-sub-form {
    max-width: 100%;
}


/* Footer Styles End */

/********************************
** HOME PAGE STYLES
*********************************/
.subhead {
    margin-bottom: 40px;
}
.subhead h2 {
    font-size: 2em;
    font-weight: 300;
    color: var(--soft-black);
    margin-bottom: 40px;
}
.subhead button,
.subhead .contact-me-btn {
    display: block;
    font-size: 1.4em;
    font-weight: 300;
    height: 55px;
    width: 340px;
    color: #fff;
    border-radius: 0;
    border: none;
    background-color: #F6754E;
    margin-bottom: 40px;
    box-shadow: 0px 0px 4px gray;
    cursor: pointer;
}

.subhead .contact-me-btn {
    font-weight: 400;
    text-decoration: none;
    padding: 10px;
    transition: all .2s ease-in-out;
}

.subhead .contact-me-btn:hover,
.subhead .contact-me-btn:focus {
    background-color: rgba(246, 117, 78, 0.90);
    color: rgb(59, 59, 59);
}

.subhead .about-img {
  width: 70%;
  padding: 84px 0 45px;
}

.subhead .about-img img {
    max-width: 100%;
    max-height: fit-content;
}

.subhead .logo-img img {
    width: 100%;
}

.subhead .arrow {
height: 23px;
  width: 23px;
  border-right: 3px solid #e6e6e6;
  border-bottom: 3px solid #e6e6e6;
  margin: 0 auto 60px;
  transform: rotate(35deg) skew(-20deg);
}

.subhead .bio div {
    font-size: 1.3em;
    width: 70%;
}

.subscribe-subhead {
    margin-bottom: 50px;
}

.subscribe-subhead h2 {
    font-size: 2em;
    font-weight: 300;
    color: var(--soft-black);
    margin-bottom: 10px;
}

.header-links-component p.pagelink,
.contact-cta p.pagelink {
    display: inline-block;
    padding:0 15px;
    font-size: 1.1em;
    line-height: 2.5;
}

.header-links-component div.pagelink:nth-child(odd),
.header-links-component p.pagelink:nth-child(odd) {
    padding: 0;
}

.header-links-component div.pagelink,
.header-links-component p.pagelink a {
    font-weight: 400;
    color: var(--link-blue);
    cursor: pointer;
}

.header-links-component div.pagelink {
    display: inline-block;
    padding: 0 15px;
    font-size: 1.1em;
}

.header-links-component div.pagelink:hover {
    text-decoration: underline;
}

.header-links-component span.divider {
    height: 100%;
    border: 1px solid var(--link-blue);
    padding: 0;
}

.card-body img.card-logo {
    margin: -30px 10px 0 0;
    width: 80px;
}
.star-rating {
    display: inline-block;
    margin-bottom: 25px;
}

.star-rating {
    margin-left: 10px;
}
.star-rating i.fas {
    color: skyblue;
    border: lightgray;
}

.star-rating i.far {
    color: lightgray;
}

.review-card {
    margin-bottom: 30px;
}

.review-card p.review-text {
  min-height: 100px;
}

.review-card p.reviewer-info {
  margin-right: 35px;
}

.review-card p,
.modal .card-body p {
    /* display: inline-block; */
    font-size: 0.85em;
}

.review-card p.reviewer-info,
.review-card .review-date,
.modal .card-body p.reviewer-info,
.modal .card-body .review-date {
    font-size: 0.85em;
    font-weight: 400;
    margin-bottom: 0;
}

.newsletters .home-page {
    margin-bottom: 165px;
}

.newsletters .card {
    padding: 10px;
}

.newsletters .card-body {
    padding: 20px 0;
}

.newsletters .card-img-top {
  margin-bottom: 0;
}

.newsletters .card-body h3 {
    font-size: 1em;
}
.newsletters .card-body .card-text-more {
    margin-bottom: 0;
}

.newsletters .card-body p.card-text-main {
    min-height: 80px;
}

.newsletters .card .card-footer {
  border: 1px solid rgba(0,0,0,.125);
}

.newsletters .card-footer small,
.newsletters .card-footer span {
  display: inline-block;
}

span.read-more {
    font-size: .85em;
    font-weight: 400;
}

// GOOGLE MAPS
.google-map {
    color: white;
    background-image: url(./assets/images/google-map.png);
    background-color: var(--footer-bg);
    min-height: 480px;
    background-position: 50% 37%;
    background-repeat: no-repeat;
}

#map {
    height: 30vh;
    width: 100vw;
}
/* Optional: Makes the sample page fill the window. */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

/********************************
** NEWSLETTERS STYLES
*********************************/
.newsletters .news-page {
    margin-bottom: 40px;
}

.contact-cta .cta-contact-link,
.contact-cta a.cta-contact-link, {
    display: inline-block;
    margin: 5px 0;
    padding:0 20px;
    font-weight: 400;
    font-size: 1.3em;
    line-height: 2.5;
    border: none;
    color: var(--link-blue);
    cursor: pointer;
}

.contact-cta a.cta-contact-link:hover {
    text-decoration: none;
}

button.cta-contact-link {
    background-color: white;
}

.contact-cta span.divider {
    height: 100%;
    border: 1px solid var(--link-blue);
    padding: 0;
}

.contact-icon {
    padding-right: 7px;
}

.newsletter-social-row {
    font-size: 2.6em;
}

.pinterest-icon {
    color: #ba0918;
}

.youtube-icon {
    color: #ff0000;
}

.yelp-icon {
    color: #ad0707;
}

.foursquare-icon {
    color: #fc5677;
}

.zillow-icon {
    color: #0075e3;
}

.avvo-icon {
    color: #00457a;
}

.lawyerscircle-icon {
    color: #8fc7f2;
}

.realtorscircle-icon {
    color: #f21d32;
}

.twitter-icon {
    color: var(--twitter);
}

.linkedin-icon {
    color: var(--linkedin);
}

.googleplus-icon {
    color: var(--google-instagram);
}

.facebook-icon {
    color: var(--facebook);
}

.instagram-icon {
    color: var(--google-instagram);
}

.single-newsletter-image {
    width: 100%;
}
.campaign-content table {
    margin: 0 auto;
}

/********************************
** NEWSFEED STYLES (Social Wall)
*********************************/
.social-wall-header {
    margin-left: 200px;
    color: var(--social-wall-header-gray);
}

/********************************
** MODAL STYLES
*********************************/
.modal.show .modal-dialog {
    margin-top: 50px;
}
.modal-body .headshot-image img {
    height: 85px;
    width: 85px;
}

.modal #materialize .submit-btn-wrapper {
    margin-bottom: 0;
}

/* Modal Component */
.modal-overlay-div {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: rgba(0,0,0,.65);
}

.modal-dialog-div {
    position: relative;
    outline: 0;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    max-width: 520px;
    cursor: default;
    border-radius: 4px;
}

@keyframes modal {
    0% { top: -150px; opacity: 0; }
    100% { top: 0; opacity: 1; }
}

.modal-global .modal-body {
    padding-bottom: 0;
}

.modal-global .card-body {
    text-align: left;
}

.modal-global .review-card {
    padding: 0;
}

.modal-global .header-info p.client-name {
    font-size: 1.2em;
    line-height: 1.2;
}

.modal-global .headshot-image img {
    margin-top: -60px;
    left: 22px;
}

.modal-global p.review-text {
    min-height: 100px;
}

/********************************
** REVIEWS STYLES
*********************************/
.reviews .review-card .card {
    cursor: pointer;
}

.reviews .review-card .card:hover {
    box-shadow: 0 0 3px #9e9e9e;
}

.leave-review-button {
    margin: 0 auto;
}

/* Modal Styles Start */
.modal p.reviewer-info,
.modal .review-date {
    font-size: 0.85em;
    font-weight: 400;
}
/* Modal Styles End */

/********************************
** VIDEOS STYLES
*********************************/
.subhead button.videos-contact-btn {
    background-color:#61D69C;
}

.video-iframe iframe {
    margin-bottom: 55px;
}



/********************************
** CONTACT STYLES
*********************************/
.required-fields {
    margin: 5px 0 8px 220px;
}

.md-form {
    // DatePicker Styles Start
    .react-datepicker-wrapper {
        width: 100%;
        .react-datepicker__input-container {
            width: 100%;
            input {
                width: 100%;
            }
        }
    }
    .react-datepicker-wrapper {
        border-bottom: 1.1px solid #999;
    }
    // DatePicker Styles End
    // Modal Styles Start
    .modal-form {
        .form-group {
            margin: 10px 0;
        }

        .header-info p.client-name {
            margin-bottom: 10px;
        }

        .modal-section-message {
            margin-bottom: 0;
        }
        .general-inquiry {
          margin-top: 20px;
        }
    }

    .modal-star-rating {
        position: relative;
        left: 50%;
        margin-left: -97px;
    }

    .form-group.modal-email {
        display: block;
        width: 100%;
    }
    // Modal Styles End
    .button-container {
      text-align: center;
    }

    .chat-form {
        .form-group {
            margin: 50px 0;
        }
    }

    .contact-form-container {
        width: 60%;
    }

    .form-group.chat-text-field,
    .form-group.chat-phone-field,
    .form-group.chat-message-field, {
        margin: 6px 0;
        display: block;
        width: 100%;

        p.danger {
            margin-bottom: 0;
        }
    }

    .recaptcha-container {
        position: relative;
        left: 50%;
        margin-left: -160px;
    }

    .recaptcha-chat-container {
        position: relative;
        left: 50%;
        margin-left: -152px;
    }

    .hidden {
        display: none;
    }

    p.error.danger {
        color: red;
    }

    fieldset {
      margin: 0 0 3rem;
      padding: 0;
      border: none;
    }

    .form-subhead {
        font-size: 1.6em;
        font-weight: 300;
        margin-bottom: 1.5em;
    }

    .form-group.feedback,

    .form-group.general-inquiry,
    .form-group.review-form-modal,
    .form-group.google-address,
    .form-group.referrer-message
    {
      display: block;
      width: 100%;
      border: 1px solid #999;
      border-radius: .5em;
    }

    .form-group.referrer-email {
        display: block;
    }

    .form-radio,
    .form-group {
      position: relative;
      display: inline-block;
      width: 50%;
      padding: 0 15px;
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-align: left;
    }

    .form-inline > .form-group,
    .form-inline > .btn {
      display: inline-block;
      margin-bottom: 0;
    }

    .form-help {
      margin-top: 0.125rem;
      margin-left: 0.125rem;
      color: #b3b3b3;
      font-size: 0.8rem;
    }
    .checkbox .form-help, .form-radio .form-help, .form-group .form-help {
      position: absolute;
      width: 100%;
    }
    .checkbox .form-help {
      position: relative;
      margin-bottom: 1rem;
    }
    .form-radio .form-help {
      padding-top: 0.25rem;
      margin-top: -1rem;
    }

    .form-group input {
      height: 1.9rem;
    }
    .form-group textarea {
      resize: none;
    }

    .form-group select {
      width: 100%;
      font-size: 1rem;
      height: 1.9rem;
      padding: 0.125rem 0.125rem 0.0625rem;
      background: none;
      border: none;
      line-height: 1.6;
      box-shadow: none;
    }
    .form-group .control-label {
      position: absolute;
      top: 0.25rem;
      pointer-events: none;
      padding-left: 0.125rem;
    //   z-index: 1;
      color: #b3b3b3;
      font-size: 1rem;
      font-weight: normal;
      -webkit-transition: all 0.28s ease;
      transition: all 0.28s ease;
    }

    .form-group.chat-message-field .control-label {
        padding-top: 8px;
    }
    .form-group .bar {
      position: relative;
      border-bottom: 1.1px solid #999;
      display: block;
    }
    .form-group .bar::before {
      content: '';
      height: 0.125rem;
      width: 0;
      left: 50%;
      bottom: -0.0625rem;
      position: absolute;
      background: #337ab7;
      -webkit-transition: left 0.28s ease, width 0.28s ease;
      transition: left 0.28s ease, width 0.28s ease;
      z-index: 2;
    }
    .form-group input,
    .form-group textarea {
      display: block;
      background: none;
      padding: 0.125rem 0.125rem 0.0625rem;
      font-size: 1rem;
      border-width: 0;
      border-color: transparent;
      line-height: 1.9;
      width: 100%;
      color: transparent;
      -webkit-transition: all 0.28s ease;
      transition: all 0.28s ease;
      box-shadow: none;
    }
    .form-group input[type="file"] {
      line-height: 1;
    }
    .form-group input[type="file"] ~ .bar {
      display: none;
    }
    .form-group select,
    .form-group input:focus,
    .form-group input:valid,
    .form-group input.form-file,
    .form-group input.has-value,
    .form-group textarea:focus,
    .form-group textarea:valid,
    .form-group textarea.form-file,
    .form-group textarea.has-value {
      color: #333;
    }
    .form-group select ~ .control-label,
    .form-group input:focus ~ .control-label,
    .form-group input:valid ~ .control-label,
    .form-group input.form-file ~ .control-label,
    .form-group input.has-value ~ .control-label,
    .form-group textarea:focus ~ .control-label,
    .form-group textarea:valid ~ .control-label,
    .form-group textarea.form-file ~ .control-label,
    .form-group textarea.has-value ~ .control-label {
      font-size: 0.8rem;
      color: gray;
      top: -1rem;
      left: 0;
    }
    .form-group.general-inquiry select ~ .control-label,
    .form-group.general-inquiry input:focus ~ .control-label,
    .form-group.general-inquiry input:valid ~ .control-label,
    .form-group.general-inquiry input.form-file ~ .control-label,
    .form-group.general-inquiry input.has-value ~ .control-label,
    .form-group.general-inquiry textarea:focus ~ .control-label,
    .form-group.general-inquiry textarea:valid ~ .control-label,
    .form-group.general-inquiry textarea.form-file ~ .control-label,
    .form-group.general-inquiry textarea.has-value ~ .control-label,
    .form-group.referrer-message select ~ .control-label,
    .form-group.referrer-message input:focus ~ .control-label,
    .form-group.referrer-message input:valid ~ .control-label,
    .form-group.referrer-message input.form-file ~ .control-label,
    .form-group.referrer-message input.has-value ~ .control-label,
    .form-group.referrer-message textarea:focus ~ .control-label,
    .form-group.referrer-message textarea:valid ~ .control-label,
    .form-group.referrer-message textarea.form-file ~ .control-label,
    .form-group.referrer-message textarea.has-value ~ .control-label {
      font-size: 0.8rem;
      color: gray;
      top: -1.5rem;
      left: 0;
    }
    .form-group select:focus,
    .form-group input:focus,
    .form-group textarea:focus {
      outline: none;
    }
    .form-group select:focus ~ .control-label,
    .form-group input:focus ~ .control-label,
    .form-group textarea:focus ~ .control-label,
    .form-group.general-inquiry select:focus ~ .control-label,
    .form-group.general-inquiry input:focus ~ .control-label,
    .form-group.general-inquiry textarea:focus ~ .control-label,
    .form-group.referrer-message select:focus ~ .control-label,
    .form-group.referrer-message input:focus ~ .control-label,
    .form-group.referrer-message textarea:focus ~ .control-label {
      color: #337ab7;
    }
    .form-group select:focus ~ .bar::before,
    .form-group input:focus ~ .bar::before,
    .form-group textarea:focus ~ .bar::before {
      width: 100%;
      left: 0;
    }

    .checkbox label,
    .form-radio label {
      position: relative;
      cursor: pointer;
      padding-left: 2rem;
      text-align: left;
      color: #333;
      display: block;
    }
    .checkbox input,
    .form-radio input {
      width: auto;
      opacity: 0;
      position: absolute;
      left: 0;
    }
    p.recruiting-date-time-control-label {
        font-weight: 400;
        min-height: 25px;
        font-size: 0.8rem;
        color: gray;
        top: -1rem;
        left: 0;
        margin-bottom: 0;
        margin-top: -26px;
    }
    p.date-time-control-label {
      font-weight: 400;
      min-height: 25px;
      font-size: 0.8rem;
      color: gray;
      top: -1rem;
      left: 0;
      margin-bottom: 0;
    }
    .radio {
        display: inline-block;
      margin-bottom: 1rem;
    }
    .form-radio label {
        display: inline-block;
        margin: 0 5px;
    }
    .radio .helper {
      position: absolute;
    //   top: -0.25rem;
      left: -0.25rem;
      cursor: pointer;
      display: block;
      font-size: 1rem;
      -webkit-user-select: none;
        -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      color: #999;
    }
    .radio .helper::before, .radio .helper::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: 0.25rem;
      width: 1rem;
      height: 1rem;
      -webkit-transition: -webkit-transform 0.28s ease;
      transition: -webkit-transform 0.28s ease;
      transition: transform 0.28s ease;
      transition: transform 0.28s ease, -webkit-transform 0.28s ease;
      border-radius: 50%;
      border: 0.125rem solid currentColor;
    }
    .radio .helper::after {
      -webkit-transform: scale(0);
              transform: scale(0);
      background-color: #337ab7;
      border-color: #337ab7;
    }
    .radio label:hover .helper {
      color: #337ab7;
    }
    .radio input:checked ~ .helper::after {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    .radio input:checked ~ .helper::before {
      color: #337ab7;
    }

    .checkbox {
      margin-top: 3rem;
      margin-bottom: 2rem;
      width: 40%;
    }
    .checkbox .helper {
      color: #999;
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      z-index: 0;
      margin-top: 4px;
      border: 0.125rem solid currentColor;
      border-radius: 0.0625rem;
      -webkit-transition: border-color 0.28s ease;
      transition: border-color 0.28s ease;
    }
    .checkbox .helper::before, .checkbox .helper::after {
      position: absolute;
      height: 0;
      width: 0.2rem;
      background-color: #337ab7;
      display: block;
      -webkit-transform-origin: left top;
              transform-origin: left top;
      border-radius: 0.25rem;
      content: '';
      -webkit-transition: opacity 0.28s ease, height 0s linear 0.28s;
      transition: opacity 0.28s ease, height 0s linear 0.28s;
      opacity: 0;
    }
    .checkbox .helper::before {
      top: 0.65rem;
      left: 0.38rem;
      -webkit-transform: rotate(-135deg);
              transform: rotate(-135deg);
      box-shadow: 0 0 0 0.0625rem #fff;
    }
    .checkbox .helper::after {
      top: 0.3rem;
      left: 0;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
    }
    .checkbox label:hover .helper {
      color: #337ab7;
    }
    .checkbox input:checked ~ .helper {
      color: #337ab7;
    }
    .checkbox input:checked ~ .helper::after, .checkbox input:checked ~ .helper::before {
      opacity: 1;
      -webkit-transition: height 0.28s ease;
      transition: height 0.28s ease;
    }
    .checkbox input:checked ~ .helper::after {
      height: 0.5rem;
    }
    .checkbox input:checked ~ .helper::before {
      height: 1.2rem;
      -webkit-transition-delay: 0.28s;
              transition-delay: 0.28s;
    }

    // .radio + .radio,
    .checkbox + .checkbox {
      margin-top: 1rem;
    }

    .has-error .legend.legend, .has-error.form-group .control-label.control-label {
      color: #d9534f;
    }
    .has-error.form-group .form-help,
    .has-error.form-group .helper, .has-error.checkbox .form-help,
    .has-error.checkbox .helper, .has-error.radio .form-help,
    .has-error.radio .helper, .has-error.form-radio .form-help,
    .has-error.form-radio .helper {
      color: #d9534f;
    }
    .has-error .bar::before {
      background: #d9534f;
      left: 0;
      width: 100%;
    }

    .button {
      position: relative;
      background: currentColor;
      border: 1px solid currentColor;
      font-size: 1.1rem;
      color: #4f93ce;
      margin: 3rem 0;
      padding: 0.75rem 3rem;
      cursor: pointer;
      -webkit-transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
      transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
      overflow: hidden;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
    .button span {
      color: #fff;
      position: relative;
      z-index: 1;
    }
    .button::before {
      content: '';
      position: absolute;
      background: #071017;
      border: 50vh solid #1d4567;
      width: 30vh;
      height: 30vh;
      border-radius: 50%;
      display: block;
      top: 50%;
      left: 50%;
      z-index: 0;
      opacity: 1;
      -webkit-transform: translate(-50%, -50%) scale(0);
              transform: translate(-50%, -50%) scale(0);
    }
    .button:hover {
      color: #337ab7;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    }
    .button:active::before, .button:focus::before {
      -webkit-transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
      transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
      transition: transform 1.12s ease, opacity 0.28s ease 0.364s;
      transition: transform 1.12s ease, opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
      -webkit-transform: translate(-50%, -50%) scale(1);
              transform: translate(-50%, -50%) scale(1);
      opacity: 0;
    }
    .button:focus {
      outline: none;
    }

    @media screen and (max-width: 576px) {
      .form-radio,
      .form-group {
        position: relative;
        display: block;
        width: 100%;
      }

      // Modal Form Styles Start
      .form-group,
      .form-radio {
        margin: 10px 0;
      }

      .form-group.field-type-select {
        margin-top: 20px;
      }

      .submit-btn-wrapper p {
          font-size: .85em;
      }
      // Modal Form Styles End

      p.recruiting-date-time-control-label {
        // font-weight: 400;
        // min-height: 25px;
        // font-size: 0.8rem;
        // color: gray;
        // top: -1rem;
        // left: 0;
        // margin-bottom: 0;
        margin-top: 0;
    }
    }
  }

/********************************
** MODAL FORM CONTACT STYLES
*********************************/
.contact-btn  {
    border-radius: 2px;
    color: #fff;
    background-color: #61D69C;
    box-shadow: 0px 0px 4px gray;
}

.form-context-msg {
    font-size: 1.2em;
    font-weight: 300;
    margin-bottom: 1.5em;
}

/********************************
** SUBMIT SUCCESS ANIMATION STYLES **
*********************************/
.check_mark {
    width: 80px;
    height: 130px;
    margin: 0 auto;
  }

  button {
    cursor: pointer;
    margin-left: 15px;
  }

  .hide{
    display:none;
  }

  .sa-icon {
    width: 80px;
    height: 80px;
    border: 4px solid gray;
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    margin: 20px auto;
    padding: 0;
    position: relative;
    box-sizing: content-box;
  }

  .sa-icon.sa-success {
    border-color: #4CAF50;
  }

  .sa-icon.sa-success::before, .sa-icon.sa-success::after {
    content: '';
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 120px;
    background: white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .sa-icon.sa-success::before {
    -webkit-border-radius: 120px 0 0 120px;
    border-radius: 120px 0 0 120px;
    top: -7px;
    left: -33px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 60px 60px;
    transform-origin: 60px 60px;
  }

  .sa-icon.sa-success::after {
    -webkit-border-radius: 0 120px 120px 0;
    border-radius: 0 120px 120px 0;
    top: -11px;
    left: 30px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0px 60px;
    transform-origin: 0px 60px;
  }

  .sa-icon.sa-success .sa-placeholder {
    width: 80px;
    height: 80px;
    border: 4px solid rgba(76, 175, 80, .5);
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2;
  }

  .sa-icon.sa-success .sa-fix {
    width: 5px;
    height: 90px;
    background-color: white;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .sa-icon.sa-success.animate::after {
    -webkit-animation: rotatePlaceholder 4.25s ease-in;
    animation: rotatePlaceholder 4.25s ease-in;
  }

  .sa-icon.sa-success {
    border-color: transparent\9;
  }
  .sa-icon.sa-success .sa-line.sa-tip {
    -ms-transform: rotate(45deg) \9;
  }
  .sa-icon.sa-success .sa-line.sa-long {
    -ms-transform: rotate(-45deg) \9;
  }

  .animateSuccessTip {
    -webkit-animation: animateSuccessTip 0.75s;
    animation: animateSuccessTip 0.75s;
  }

  .animateSuccessLong {
    -webkit-animation: animateSuccessLong 0.75s;
    animation: animateSuccessLong 0.75s;
  }

  @-webkit-keyframes animateSuccessLong {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
  @-webkit-keyframes animateSuccessTip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }
  @keyframes animateSuccessTip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes animateSuccessLong {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }

  .sa-icon.sa-success .sa-line {
    height: 5px;
    background-color: #4CAF50;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
  }

  .sa-icon.sa-success .sa-line.sa-tip {
    width: 25px;
    left: 14px;
    top: 46px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .sa-icon.sa-success .sa-line.sa-long {
    width: 47px;
    right: 8px;
    top: 39px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .submitSuccessMessage {
    word-break: break-all;
  }

  @-webkit-keyframes rotatePlaceholder {
    0% {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    5% {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    12% {
      transform: rotate(-405deg);
      -webkit-transform: rotate(-405deg);
    }
    100% {
      transform: rotate(-405deg);
      -webkit-transform: rotate(-405deg);
    }
  }
  @keyframes rotatePlaceholder {
    0% {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    5% {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    12% {
      transform: rotate(-405deg);
      -webkit-transform: rotate(-405deg);
    }
    100% {
      transform: rotate(-405deg);
      -webkit-transform: rotate(-405deg);
    }
  }

/********************************
** CHAT-WINDOW STYLES **
*********************************/

.chat-window {
    border: none;
    width: 20rem;
    height: 29.7rem;
    position: fixed;
    box-shadow: 0px 0px 20px gray;
    bottom: -490px;
    right: 10px;
    z-index: 9998;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-name: chat-popup;
    animation-fill-mode: forwards;
}

.slide-in-form {
    border: none;
    width: 20rem;
    height: 25.7rem;
    position: fixed;
    bottom: 5px;
    right: -340px;
    z-index: 9999;
    animation-name: slide-in;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

.slide-in-form .card-body {
    padding: 0 10px 10px 10px;
}

@keyframes chat-popup {
    0% { bottom: -478px; }
    100% { bottom: 5px; }
}

@keyframes slide-in {
    0% { right: -340px; }
    100% { right: 10px; }
}

@keyframes fade-in {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.chat-window .card-body {
    padding: 0;
}

p.chat-disclaimer {
    font-size: 10px;
}

.chat-card .card-header {
    background-color: var(--link-blue);
    color: white;
}

.chat-card .card-header button {
    color: white;
}

.chat-card .card-body .chat-image img {
    height: 65px;
    width: 65px;
    border-radius: 50%;
}

.chat-card .card-body .chat-header {
    font-size: .85rem;
    font-weight: 500;
    margin: 16px;
    padding: 0;
}

.chat-card .card-body .chat-form-header {
    font-size: .85rem;
    font-weight: 500;
    padding: 0;
}

.chat-card .card-body .chat-header-no-image {
    font-size: .85rem;
    font-weight: 500;
    margin: 25px 18px;
    padding: 0;
}

.chat-card .card-body .chat-image-heading {
   margin: 0;
   height: 130px;
}

.chat-card .card-body .chat-textarea {
    margin-top: 10px;
    line-height: 1.5;
}

.chat-card .card-body .chat {
    height: 245px;
    font-weight: 500;
    overflow: auto;
}

.chat-card .card-body .chat-input button {
    background-color: var(--link-blue);
    color: white;
    margin: 0;
}

.chat-success {
    line-height: 1.5;
    opacity: 0;
    animation-name: fade-in;
    animation-duration: 1s;
    animation-fill-mode: forwards;

}

.chat-close-btn  {
    border-radius: 2px;
    color: #fff;
    background-color: var(--link-blue);
    box-shadow: 0px 0px 4px gray;
}

/********************************
** UNSUBSCRIBE STYLES
*********************************/
.unsubscribe-form {
    width: 350px;
    font-size: 1.1em;
}

.unsubscribe-form label {
    padding-left: 10px;
}

.unsubscribe-btn {
    background-color: #61D69C;
    border: none;
}
.form-group.unsubscribe-hidden {
    display: block;
    width: 42%
}

.form-group.unsubscribe-hidden input {
    width: 100%;
    height: 28px;
    padding-top: 0;
}

.form-group.unsubscribe-hidden textarea {
    width: 100%;
    height: 28px;
    padding-top: 0;
}

.form-group.resub {
    display: inline-block;
    width: 42%
}

.resub-btn {
    display: inline-block;
}

.resub-btn button {
    margin-bottom: 5px;
}
/********************************
** CUSTOM PAGE STYLES
*********************************/
.custom-container {
    width: 65%;
    margin: 0 auto;
}

.custom-layout {
    margin: 55px 0 50px 0;
}

.text-layout {
    margin: 35px 0 70px 0;
}

.custom-layout img {
    max-width: 100%;
    height: auto;
}

.text-only {
    width: 97%;
}

.custom-right {
    padding-left: 18px;
}

/********************************
** FEEDBACK SUCCESS PAGE STYLES
********************************/
.feedback-success-img {
    margin: 35px 0 30px 0;
}

.feedback-success-img .feedback-headshot-image img {
    height: 205px;
    width: 205px;
    border-radius: 50%;
    border: 3px solid #FFF;
}

.feedback-headshot-image img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 3px solid #FFF;
}

.success {
    margin-top: 110px;
}

.success-links-component span.divider {
    height: 60%;
    border: 1px solid rgb(158, 171, 184);
    padding-top: 5px;
    display: inline-block;
}

.success-links-component p.pagelink {
    display: inline-block;
    padding: 0 4px;
    margin-bottom: 0;
}

.social-link a {
    text-decoration: none;
    font-size: 1.5rem;
}

/***********************************
** KELLER WILLIAMS RECRUITING STYLES
************************************/

.kwr-content {
    width: 73%;
    font-size: 1.3rem;
}

.kwr-heading {
    margin: 50px 0;
}

.kwr-heading h1 {
    font-size: 2.1rem;
}

.kwr-button {
    display: block;
    font-size: 1.4em;
    height: 50px;
    width: 180px;
    color: #fff;
    border-radius: .25rem;
    border: none;
    background-color: #F6754E;
    margin-bottom: 40px;
    font-weight: 400;
    padding: 8px;
    transition: all .2s ease-in-out;
}

.kwr-button-container a:hover {
    background-color: rgba(246, 117, 78, 0.90);
    color: rgb(59, 59, 59);
    text-decoration: none;
}

/***********************************
** BROWSER NOT SUPPORTED STYLES
************************************/

.not-supported-heading {
    h1 {
        font-size: 4.9rem;
        margin: 110px 0 15px 0;
    }

    h6 {
        font-size: 1.5rem;
        margin-bottom: 70px;
    }

    p {
        font-size: 1.25rem;
        font-weight: 500;

    }
}

.not-supported-image {
    margin-bottom: 87px;

    img {
        max-width: 60%;
    }
}

/********************************
** MEDIA QUERY STYLES
*********************************/

@media screen and (max-width: 768px) {
  .subhead .about-img {
    width: 100%;
    padding: 42px 0 25px;
  }
    .success-links-component p.pagelink:nth-child(even) {
        display: none;
    }

    .success-links-component p.pagelink {
        display: block;
        margin-bottom: 4px;
    }

    .md-form .contact-form-container {
        width: 100%
    }

    .required-fields {
        margin: 6px 0 16px 5px;
    }
}

@media screen and (max-width: 576px) {
    /* General Styles Start */
    .review-card p.review-text {
      min-height: 120px;
    }

    img.logo {
        max-width: 65%;
    }
    /* General Styles End */

    /* Header Styles Start */
    .hero-large {
        min-height: 250px;
    }

    .hero-small {
        min-height: 190px;

        h1{
          padding-top: 0;
        }
    }

    .hero-large h1,
    .hero-small h1 {
        font-size: 2.4rem;
    }

    .header-large .headshot-image img {
      height: 120px;
      width: 120px;
      border-radius: 50%;
      border: 3px solid #FFF;
      position: relative;
      margin-top: -60px;
    }

    .header-info {
        font-size: 1.4rem;
        line-height: 1.4;
        p {
          margin-bottom: 1rem;
        }

        p.client-company {
          margin-bottom: 1rem;
        }
    }

    .header-info p.client-name {
        line-height: 1.2;
    }

    .greeting {
      h1 {
        margin: 30px 20px 10px;
      }
      p {
        padding-top: 0;
        font-size: 1.2rem;
        margin-bottom: 80px;
      }
    }

    .header-small-greeting {
      h1 {
      margin: 12px 20px 10px;
      }
      p {
        font-size: 1.2rem;
        margin-bottom: 80px;
      }
    }

    .home-contact-btn {
      margin-top: 1rem !important;
    }

    .subhead .about-img {
      width: 100%;
      padding: 42px 0 25px;
    }

    .subhead {
      margin-bottom: 25px;

      img {
        margin-bottom: 0;
      }

      .bio div {
        font-size: 1.1rem;
        width: 88%;
      }
    }


    .subhead .contact-me-btn {
        width: 250px;
    }

    .modal .header-info {
        line-height: 1.5;
    }

    .form-context-msg {
        font-size: 1em;
    }
    /* Header Styles End */

    /* Homepage Styles Start */
    .reviews p.review-item:nth-child(odd),
    .newsletters p.newsletter-item:nth-child(odd) {
        padding:0 3px;
    }

    .reviews p.review-item,
    .reviews .review-item.modal-link,
    .newsletters p.newsletter-item {
        padding:0 3px;
    }
    /* Homepage Styles End */

    /* Reviews Styles Start */
    .review-card p.reviewer-info {
      margin-right: 80px;
    }
    /* Reviews Styles End */

    /* Newsletter Styles Start */
    .contact-cta {
        font-size: 0.85em;
    }

    .newsletters .home-page {
        margin-bottom: 30px;
    }

    .newsletters .card-body p.card-text-main {
        min-height: 20px;
    }

    .newsletters .card-footer span {
      margin-left: 100px;
    }

    .contact-cta .cta-contact-link,
    .contact-cta a.cta-contact-link,
    .contact-cta p.pagelink {
        padding: 0 6px;
    }
    /* Newsletter Styles End */

    /* Modal Styles Start */
    .modal .card-body p.reviewer-info,
    .modal .card-body .review-date {
        font-size: 0.75em;
    }

    .modal-form .header-info p.client-name {
        margin-bottom: 10px;
    }
    /* Modal Styles End */

    /* Footer Styles Start */
    .footer .col-sm-4 {
        padding: 20px 0;
        text-align: center;
    }

    .footer .subscribe-btn.float-right {
        float: none !important;
    }
    /* Footer Styles End */
    .header-links-component div.pagelink,
    .header-links-component p.pagelink {
        padding: 0 6px;
        font-size: .95em;
    }
    /* Form styles */
    form.md-form {
        margin-top: 8px;
        .checkbox {
            width: 80%;
        }
    }

    .form-group.unsubscribe-hidden {
        width: 80%;
    }

    .form-group.unsubscribe-hidden input {
        width: 100%;
    }

    .form-group.resub {
        display: block;
        width: 80%;
    }

    .resub-btn {
        display: block;
    }

    .footer-sub-form {
        margin: 0 auto;
    }

    .footer-sub-btn button {
        float: none;
        margin: 0 auto;
    }

    .greeting.no-headshot {
        margin-top: 60px;
    }

    .header-small {
        margin-top: 17px;
    }

    .custom-layout {
        margin: 20px 0 30px 0;
    }

    .custom-right {
        margin-top: 17px;
        padding: 0;
    }

    .custom-container {
        width: 90%;
        margin: 0 auto;
    }

    .header-info {
        padding: 20px 0 0;
    }

    .kwr-heading {
        margin: 28px 0;
    }

    .kwr-heading h1 {
        font-size: 1.9rem;
    }

    .kwr-content {
        width: 82%;
    }

    .not-supported-heading {
        h1 {
            font-size: 2.5rem;
            margin: 55px 0 20px 0;
        }

        h6 {
            font-size: 1.1rem;
            margin-bottom: 45px;
        }

        p {
            font-size: 1.15rem;
        }
    }

    .not-supported-image {
        margin-bottom: 53px;

        img {
            max-width: 95%;
        }
    }
}

.unsubscribedEmail {
    display: block;
    margin-bottom: 24px;

    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;

    color: #333333;
}

.honey
{
    display:none;
    visibility:hidden;
}